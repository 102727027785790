<template>
  <div
    v-if="isNotEmpty(cartStore.cart?.errors)"
    class="space-y-2 rounded-md bg-red-100 px-4 py-3 leading-none text-red-600"
  >
    <div v-for="(error, i) in cartStore.cart.errors" :key="i">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
const cartStore = useCartStore()
</script>
